import { type FunctionComponent } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { MemberBenefitsInfo } from '../../../../generated/frontend'
import { useMemberBenefitsInfoQuery } from '../../../../generated/frontend'
import { PersonalisedMemberBenefitsLoggedIn } from './components/LoggedIn/PersonalisedMemberBenefitsLoggedIn'
import { PersonalisedMemberBenefitsLoggedOut } from './components/LoggedOut/PersonalisedMemberBenefitsLoggedOut'
import { LoggedOutMemberBenefitsV2 } from './components/LoggedOutV2/LoggedOutMemberBenefitsV2'

export const LoggedOutMemberPanel = ({
  memberBenefitsInfo,
  ...rest
}: {
  memberBenefitsInfo: MemberBenefitsInfo
}) => {
  const showCreativeMemberPanel = useFeatureIsOn('DSP-016-improved-member-panel')

  if (showCreativeMemberPanel) {
    return (
      <LoggedOutMemberBenefitsV2
        creative={memberBenefitsInfo.creative.val}
        caption={memberBenefitsInfo.caption}
        overline={memberBenefitsInfo.overline}
        widgetLinks={memberBenefitsInfo.links}
        data-testid="logged-out-benefits-v2"
        {...rest}
      />
    )
  }

  return (
    <PersonalisedMemberBenefitsLoggedOut
      calloutText={memberBenefitsInfo.calloutText}
      caption={memberBenefitsInfo.caption}
      overline={memberBenefitsInfo.overline}
      widgetLinks={memberBenefitsInfo?.links}
      data-testid="logged-out-benefits"
      {...rest}
    />
  )
}

export const PersonalisedMemberBenefits: FunctionComponent = ({ ...rest }) => {
  const { data: { memberBenefitsInfo } = {}, loading } = useMemberBenefitsInfoQuery()

  if (loading) {
    return null
  }

  if (memberBenefitsInfo === undefined) {
    return null
  }

  const isLoggedIn = memberBenefitsInfo?.isLoggedIn

  if (isLoggedIn) {
    return (
      <PersonalisedMemberBenefitsLoggedIn
        links={memberBenefitsInfo?.links}
        offer={memberBenefitsInfo?.offer}
        renewalOffer={memberBenefitsInfo?.renewalOffer}
        data-testid="logged-in-benefits"
        {...rest}
      />
    )
  }

  if (memberBenefitsInfo.creative.isQueryParam) {
    return (
      <LoggedOutMemberBenefitsV2
        creative={memberBenefitsInfo.creative.val}
        caption={memberBenefitsInfo.caption}
        overline={memberBenefitsInfo.overline}
        widgetLinks={memberBenefitsInfo.links}
        data-testid="logged-out-benefits-v2"
        {...rest}
      />
    )
  }

  return <LoggedOutMemberPanel memberBenefitsInfo={memberBenefitsInfo} {...rest} />
}
